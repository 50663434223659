import React from "react"

// components
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import { useLocation } from "@reach/router"

import FamiliesGrid from "../components/FamiliesGrid"
import EmailSection from "../components/EmailSection"
import Subscribe from "../components/Subscribe"
import NewsCarousel from "../components/NewsCarousel"

const disasterCarouselInfo = {
  // first slide parameters
  title: "Help a neighbor impacted by recent hurricanes",
  subtitle:
    "Our vetted partners are already on the ground sourcing unmet needs so we can help close the gap for households in acute need.",
  hideDonate: true,
  hideGiftCard: true,

  // second slide which replaces why neighborshare
  testimonial:
    "“What NeighborShare does, I cannot tell you the relief it brings. When you’re standing on $0, $400 is huge. Your gifts are often the difference between quitting or getting to the next level.” - Catherine McDowell, Executive Director, Roots of Recovery",

  // third slide which changes a bit about how it works
  secondaryTitle:
    "Give immediately to validated households via our local service partners",
}

const SponsorHome = args => {
  const location = useLocation()
  // Hacky way to check if the pageContext is a sponsor
  const sponsor = args.pageContext.hasOwnProperty("filter_criteria_needs_type")
    ? args.pageContext
    : null

  const getCarouselInfo = () => {
    if (location.pathname === "/disaster") {
      return disasterCarouselInfo
    }
    if (sponsor) {
      const sponsorName = sponsor?.name || ""

      const sponsorNeeds = sponsor?.filter_criteria_needs_type
        ? JSON.parse(sponsor.filter_criteria_needs_type)
        : []

      const sponsorLocation = sponsor?.filter_criteria_geo
        ? JSON.parse(sponsor.filter_criteria_geo)
        : []

      const sponsorNeedsText = sponsorNeeds
        ?.map(n => n.toLowerCase())
        .join(", ")
        .replace(/, ([^,]*)$/, " and $1")
      const sponsorLocationText = sponsorLocation
        ?.map(location => location.split(",")[0])
        .join(", ")
        .replace(/, ([^,]*)$/, " and $1")

      return {
        title:
          sponsor?.landing_page_copy?.headline ||
          `Help a neighbor with ${sponsorName}`,
        subtitle:
          sponsor.landing_page_copy?.subheadline ||
          `Partnering with ${sponsorName} to support ${sponsorNeedsText} in ${sponsorLocationText}. Join us!`,
      }
    }
  }

  const organizations = args.pageContext.hasOwnProperty("organization_id")
    ? [args.pageContext.organization_id]
    : args.pageContext.hasOwnProperty("organization_ids")
    ? args.pageContext.organization_ids
    : null

  const organizationText = args.pageContext.hasOwnProperty("organizationText")
    ? args.pageContext.organizationText
    : null

  return (
    <>
      <SEO />
      <Hero carouselInfo={getCarouselInfo()} />
      <FamiliesGrid
        sponsor={sponsor}
        organizations={organizations}
        organizationText={organizationText}
      />
      <EmailSection />
      <NewsCarousel />
      <Subscribe />
    </>
  )
}

export default SponsorHome
